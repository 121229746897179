
.custom-page{
	width: 100%;
    padding: 1rem .5rem .5rem 0rem;
    display: flex;
    justify-content: flex-end;
}

.page-container{
    display: flex;
    justify-content: flex-end;
}
.page-container button{
    border: none;
    color: white;
    background-color: #11a88d;
    font-size: 1rem;
    padding: .2rem 1rem 0.2rem 1rem;
    border-radius: .25rem;
    cursor: pointer;
}
.custom-input{
    width: 18%;
    text-align: center;
    border: 1px solid #dcdcdc;
    padding: 0.2rem;
    border-radius: 0.25rem;
    margin: 0rem .5rem 0 0.5rem;
}