.inplay-match-container{
    border: 1px solid #dbdbdb;
    margin: 1rem;
    border-radius: .25rem;
    padding-bottom: .5rem;
}
.inplay-match-body-content{
    padding: 1rem .5rem 1.5rem 1rem;
}
.button-inplay{
    background-color: #22bb92;
    padding: .2rem;
    color: white;
    text-transform: uppercase;
    margin-left: 1rem;
    display: flex;
    border-radius: 2px;
}


.inplay-match-body{
    display: flex;
    align-items: center;
    
}
.inplay-match-body-content h4{
    font-weight: normal;
    font-size: 1.5rem;
    text-transform: uppercase;
}
.inplay-tab-container{
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    /* padding: .5rem; */
}
.inplay-tab{
    padding: .4rem 1rem .4rem 1rem;
    cursor: pointer;
    color: gray;
    font-weight: 600;
    font-size: .875rem;
}
.inplay-tab:hover{
    color:#12bcca;
}
.active-inplay-tab{
    background-color: white;
    text-align: center;
    color: #12bcca;
    border-bottom: 2px solid #12bcca;;
}

.inplay-time{
    color: #8b8b8b;
}
