
.book-maker-market{
border-radius: .25rem;
padding-bottom: 1rem;
margin-top: 1rem;
width: 100%;
/* background-color:#F0F0F0; */
transition: 0.4s linear;
}
.offline-btn{
  display: flex;
  align-items: center;
  width: 60%;
}
.market-assign-btn{
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 15%;
}
.book-maker-heading{
  background-color:#f7f7f7;
  border-bottom: 2px solid #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
}

.book-maker-heading p{
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
}
.book-maker-content{
  background-color: white;
  /* padding: 1rem 1rem 1.5rem 1rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 1rem;
}
.book-maker-select{
  width: 35%;
  /* margin: 0rem rem 0rem 1rem; */
}
.fancy-result-input{
  width: 100%;
  border: 2px solid #cbcbcb;
  padding: .5rem;
  outline: none;
  height: 2.5rem;
  border-radius: .25rem;
  margin-top: .4rem;
}

.book-maker-btn{
  width: 13%;
  margin:1rem 0rem 0rem 0rem;
}

.remove-market-container{
  width: 12.6%;
  margin-right: 1rem;
}
.book-maker-match{
  background-color:#f7f7f7 ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid #dbdbdb;
  margin: 15px 0px;
}
.book-input input{
  width: 100%;
  border: 1px solid #cbcbcb;
  padding: .5rem;
  outline: none;
  height: 2.5rem;
  /* background-color: #dbdbdb; */
  border-radius: .25rem;
 font-size: 1rem;
 
}

.text-input{
  display:flex;
  align-items: center;
}
.limit-amount{
  width: 150px;
  margin-right: 4rem;
  border: 1px solid #cbcbcb;
  padding: .4rem;
  outline: none;
  height: 2rem;
  background-color: #dbdbdb;
  border-radius: .25rem;
 font-size: 1rem;
}
.match-name{
  color: #c24556;
  font-size: 1rem;
}
.match-name-time{
  display: flex;
  align-items: center;
  padding: 0rem 0rem 0rem 1rem;
}
.match-name-time h2{
  margin-left: 1rem;
}
.market-match-container{
  background-color:white;
  padding-bottom: 1rem;
}
.market-odds-body{
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 0.2rem 0rem 0.2rem 1rem;
}

.market-odds-back{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 4rem;
}
.boder-odd{
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
}
.odds-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
 
}
.odds-container-1{
  display: flex;
  position: relative;
  margin-right: .5rem;
}
.box-odd-name{
  /* width: 90%; */
  padding: 0.2rem 0rem 0.2rem 1rem;
}
.back-box-container-one{
display: flex;

}
.suspend-box-all{
  background-color: #df1717;
  color: white;
  padding: .5rem 1.5rem .5rem 1.5rem;
  border-radius: .25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  top: 35%;
  left: 33.5%;
  z-index: 1;
}

.suspend-box, .suspend-boxx{
  background-color: #df1717;
  color: white;
  padding: .5rem 1.5rem .5rem 1.5rem;
  border-radius: .25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;

  left: 33.5%;
  z-index: 1;
}
.suspend-box {
  top: 10%;
}

.suspend-boxx {
  top: 35%;
}
.box a{
      
        font-size: 10px;
}
.box{
  border: 1px solid #dbdbdb;
  background-color: #f7f7f7;
  width: 4.5rem;
  height:3rem;
  margin: 0rem 0.1rem 0rem 0.1rem;
  text-align: center;
  border-radius: 2px;
  display: block;
  justify-content: center;
  align-items: center;
}
.market-book-maker-border{
  border-radius: 2px;
  border: 2px solid #dbdbdb;
  padding: .5rem;
  margin: .5rem .5rem 0rem .5rem;
}
.market-bookmaker-popup{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 5px;
}
.market-bookmaker-popup label {
  font-weight: bold;
}
.suspend-container{
  display: flex;
  align-items: center;
  position: relative;
  right: 17px;
}
.suspend-container-body{
  display: flex;
  align-items: center;
  margin: 0rem 1rem 0rem 1rem;
}
.suspend-para{
  margin-right: 1rem;
}

.odd-input{
  width: 100%;
  padding: .5rem;
  border: 2px solid #dbdbdb;
  border-radius: .25rem;
  margin-top: .5rem;
  outline: none;
  background-color: white;
  margin-bottom: 8px;
}
.odd-date{
  width: 20rem;
  padding: .5rem;
  border: 1px solid #dbdbdb;
  border-radius: .25rem;
  margin:0.5rem 0rem 0rem 1rem ;
  outline: none;
  /* background-color: #dbdbdb; */
}
.span-book{
  display: flex;
  align-items: center;
}
.span-book span{
  margin-left: 1rem;
}
.bookmaker-table-container{
  border-left: 2px solid #dbdbdb;
  border-right: 2px solid #dbdbdb;
  margin:0rem 0.5rem 0rem .5rem ;
  /* padding: .5rem; */
  /* border-radius: 2px; */
}

.add-bookmaker-btn{
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
}
.book-input-submit-1{
  display: flex;
  justify-content: space-between;
  width: 45.8%;
}
.book-maker-column-input-1{
  border: 1px solid #dbdbdb;
  width: 200px;
  outline: none;
  font-size: 1rem;
  border-radius: .25rem;
  background-color: white;
  padding: .3rem;
}

.book-maker-column-input{
  border: 1px solid #dbdbdb;
  width: 100px;
  outline: none;
  font-size: 1rem;
  border-radius: .25rem;
  background-color: white;
  padding: .3rem;
}


.fancy-input{
  width: 80px;
  border: 1px solid #cbcbcb;
  padding: .2rem;
  outline: none;
  height: 2rem;
  background-color: white;
  border-radius: .25rem;
 font-size: 1rem;
}

.error-message {
  font-size: 8px;
  color: red;
  display: block;
  margin-top: 2px;
}
#fancy-head-coloumn th{
  font-size: 12px;
  text-align: left;
  padding: .5rem;
  border-bottom: 1px solid #dbdbdb;
}
.fancy-head-row td{
  border-bottom: 2px solid #dbdbdb;
  padding: .5rem;
}
.fancy-stake-input{
  width: 320px;
  border: 1px solid #cbcbcb;
  padding: .2rem;
  outline: none;
  /* height: 2rem; */
  background-color: white;
  border-radius: .25rem;
 font-size: 1rem;
 margin:0.4rem 0rem 0.4rem 0rem;
}
input[type=number]{
  width: 170px;
}
.fancy-stake-input-1{
  width: 200px;
  border: 1px solid #cbcbcb;
  padding: .2rem;
  outline: none;
  /* height: 2rem; */
  background-color: white;
  border-radius: 2px;
 font-size: 1rem;
 margin:0.4rem 0rem 0.4rem 0rem;
}
.fancy-cancel{
  background-color:red;
  outline: none;
  width: 2.5rem;
  height: 2rem;
  border: none;
  display:flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.actions_tab{
display: flex;
align-items: center;
}

.fancy-submit{
  background-color:#11a88d;
  outline: none;
  width: 2.5rem;
  height: 2rem;
  border: none;
  display:flex;
  align-items: center;
  justify-content: center;
  color: white;
  /* border-radius: 2px; */
  cursor: pointer;
}


.icon{
  font-size: 1.5rem;
  text-align: center;
  outline: none;
  border: none !important;
}

.disabled-icon {
  opacity: 0.5;
  pointer-events: none;
}

.icon:focus {
  outline: none !important;
}


@media (max-width:768px) {
  .book-maker-content{
    flex-direction: column;
  }
  .market-assign-btn{
    width:100%;
  }
  .book-maker-heading{
    flex-direction: column;
    padding: 1rem; 
  }
  .book-maker-heading p{
    padding: 0;
  }
  .book-maker-match{
    flex-direction: column;
    width: 100%;
  }
  .book-maker-select{
    width: 100%;
  }
  .book-maker-btn{
    width: 100%;
  }
  .remove-market-container{
width: 100%;
margin: 1rem 0rem;
  }

  /* ##################################### */
  .book-input-submit-1{
    flex-direction: column;
    width: 60%;
  }


  .book-input{
    width: 100%;
    margin: 1rem 0rem 1rem 0rem;
  }
  .span-book{
    flex-direction: column;
  }
  .span-book span{
    margin: 0;
  }
  .match-name-time{
    flex-direction: column;
  }
 
  .market-odds-back{
    /* flex-direction: column; */
    margin: 0;
  }
  .odds-container-1{
    flex-direction: column;
    margin: 0;
  }
  .box-odd-name{
    width: 100%;
  }
  .back-box-container-one{
    /* flex-direction: column; */
    margin: 0;
  }
  .back-box-container-two{
    margin: 0;
  }
  .market-book-maker-border{
    margin: 0;
  }
  .market-bookmaker-popup{
  flex-direction: column;
  }
  .suspend-container{
     margin:1rem 0rem 1rem 0rem;
  }
  .odd-date{
    width: 100%;
    margin: 0;
  }
  .bookmaker-table-container{
    margin: 0;
  }
  
}


/* ########################### respnsive 769px and 1060px ################### */
@media (min-width:769px) and (max-width:1100px) {
  .book-input-submit-1{
    flex-direction: column;
    width: 35%;
  }
  .market-assign-btn{
    width: 48%;
  }
  .book-maker-content{
    flex-direction: column;
  }
  .remove-market-container{
    width: 30%;
  }
  .book-maker-select{
    width: 100%;
  }
  
  .book-maker-btn{
    width: 100%;
  }
  .book-input-submit-1 .submit{
    width: 100%;
    margin-top: 1rem;
  }
  .offline-btn{
    width: 100%;
  }
}



/* ############################# resonsive 450px ####################### */

@media (max-width:450px) {
  .limit-amount{
    margin:0 1rem 0 0;
  }

  .text-input .submit{
    margin-right: 0;
  }

  .book-input-submit-1{
    width: 70%;
  }
}

.flex-box {
  display: flex;
  justify-content: space-between !important;
  align-items: start !important;
  width: 100%;
  gap: 58px;
}
.main-box {
  background-color: #05414617;
  width: 65%;
  padding: 15px;
  border-radius: 5px;
}
.main-box h2{
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 8px;
  text-align: left;
  /* border-bottom: 3px solid #ddd; */
  border-bottom: 1px solid black;
}
.book-maker-select-container {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.book-maker-select {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 360px;
}

.book-maker-select select {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.book-maker-btn-container .submit {
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}