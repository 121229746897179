.navbar {
    margin: 1rem;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    overflow: auto;

}
.user-tab {
    padding: .8rem 1rem .8rem 1rem;
    background-color: white;
    display: inline-block;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 0rem 1rem 0rem 1rem;
    border-radius: 4px;
}

.user-tab:hover {
    background-color: #12bcca;
    color: white;
}

.user-tab-active {
    background-color: #12bcca;
    color: white;
}

.nav-item {
    font-size: calc(12.4375px + .1875vw);
    line-height: 1em;
    font-weight: 500;
    text-transform: capitalize;
}

.assemblay-p {
    text-transform: uppercase;
}

.world-cup-p {
    text-transform: uppercase;
}

.nav-items:hover {
    background-color: rgb(62, 181, 194);
    color: white;
    cursor: pointer;
}

.play-section {
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.sport-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.418);
    margin: 0.7rem 0;
}

.sport-heading {
    font-size: 1rem;
    text-transform: capitalize;
    color: white;
    padding: 0 0.5em;
}

.play-btn {
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    padding: 0.6em 1.1em;
    cursor: pointer;
}

/* ############################### section-2 styling ######################  */
.event-name-container {
    display: flex;
    width: 30%;
}

.league-name {
    font-size: .85rem;
    font-weight: 500 !important;
    line-height: 1rem;
}

.all-inplay-match {
    margin: 1rem;
}

.inplay-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inplay-body h3 {
    font-weight: normal;
    text-transform: capitalize;
}

.live-btn-container {
    display: flex;
    align-items: center;
}

.live-btn-container img {
    width: 30px;
    height: 30px;
}

.inplay-content {
    border: 1px solid #dbdbdb;
    border-bottom: none;
    margin-top: 1rem;
}
.inplay-odd {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    margin-top: .5rem;
    align-items: center;
    justify-content: space-between;
}

.fancy-book-container {
    margin-left: 2rem;
}

.inplay-gap {
    padding: 0rem 0rem .5rem .5rem;
    border-bottom: 1px solid #dbdbdb;
}

.sport-name {
    color: #30a9ad;
    font-size: 1rem;
    font-weight: 600;
}

.sport-time {
    color: #12bcca;
    font-size: 13px;
}

.min-max-container {
    width: 25%;
    display: flex;
    justify-content: center;
}

.min-max {
    font-size: 14px;
    font-weight: 500;
}

.bookmaker img {
    vertical-align: middle;
    width: 12px;
}

.inplay-odds-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
}

.inplay-odds-container p {
    font-size: 14px;
    font-weight: 600;
}

.inplay-box-container {
    margin-right: .5rem;
    display: flex;
}

.inplay-box {
    background-color: #8dd2f0;
    margin-right: 1px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: .5rem;
    width: 60px;
    height: 44px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.inplay-box-1 {
    background-color: #feafba;
    padding: .5rem;
    width: 60px;
    height: 44px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: center;
}

.btn-index {
    background-color: white;
    border: 1px solid black;
    padding: 0.25rem 1rem .25rem 1rem;
    font-size: .85rem;
    margin: 0rem .5rem 0rem 0.5rem;
    cursor: pointer;
    border-radius: .25rem;
}

.active-btn-index {
    background-color: rgb(62, 181, 194);
    border: none;
    border: 1px solid rgb(62, 181, 194);
    color: white;
    transition: .4s all linear;
}

/* responsive  */
@media (max-width:768px) {
    .navbar {
        margin: .5rem;
        justify-content: flex-start;
    }

    .user-tab {
        padding: .2rem;

        align-items: center;
    }

    .nav-link-tab {
        display: flex;
        align-items: center;
    }

    .inplay-box-container {
        margin-top: .5rem;
    }

    .event-name-container {
        width: 100%;
    }

    .inplay-body {
        flex-direction: column;
    }

    .inplay-tab-container {
        display: block;
    }

    .fancy-book-container {
        display: flex;
        justify-content: space-between;
        /* margin-left: 1rem; */
        /* align-items: center; */
    }

    #fancy {
        margin-left: 2rem;
    }

    .min-max-container {
        display: flex;
        justify-content: flex-end;
        width: 75%;
    }

    .inplay-odds-container {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        /* flex-direction: column; */
    }
}

@media (min-width:769px) and (max-width:1100px) {
    .navbar {
        /* width: 100%; */
    }

    .fancy-book-container {
        justify-content: flex-start;
    }

    .min-max-container {
        display: none;
    }

    #fancy {
        margin-left: 0;
    }
}