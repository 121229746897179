.accordion-clients {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.accordion-header-clients {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
}

.arrow {
  cursor: pointer !important;
}

.open-clients {
  color: red;
}

.client-btn {
  display: flex;
  align-items: center;
}

.client-btn button {
  padding: .3rem 1rem .3rem 1rem !important;
}

.client-container {
  padding: 1rem;
  background-color: #dbddbd;
}

.client-content {
  display: flex;
  justify-content: flex-end;
}

.add {
  margin-left: .5rem !important;
}

.select-client {
  width: 100%;
  margin: 12px 2px;
}

.client-accordian {
  cursor: pointer;
}

.client-active {
  color: red;
}

.client-upload-btn {
  background-color: #018990;
  border-radius: .25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  cursor: pointer;
  height: 2.3rem;
  margin-top: .2rem;
}

.option-select {
  width: 100%;
}