.sky-fancy-container{
    width: 20%;
}
.sky-fancy-content{
    display: flex;
    align-items: flex-end;
}
.sky-fancy-head{
    padding: 0.5rem;
    display: flex;
    align-items: center;
}
.sky-fancy-event{
    margin-left: 1rem;
    color: rgba(255, 0, 0, 0.8);
}
.refresh-btn-container{
    display: flex;
    align-items: center;
}
.refresh-fancy{
    margin-top: 2.1rem;
    height: 2.4rem;
    border-radius: 4px;
    background-color: rgba(128, 128, 128, 0.934);
    margin-left: 18px;
    color: white;
    cursor: pointer;
    text-align: center;
    border: none;
    padding: 0rem 2rem 0rem 2rem;
    display: flex;
    align-items: center;
}
.sky-fancy-market{
    background-color: #f7f7f7;
    margin-top: 1rem;
    /* padding: .5rem; */
    border: 2px solid #dbdbdb;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    /* border: 1px solid #dbdbdb; */
}
.sky-fancy-table{
    border-top: 2px solid #dbdbdb;
    border-collapse: collapse;
    overflow-x: auto;
}
.sky-fancy-table thead{
    background-color: white;
}
.sky-fancy-row td{
    padding: .5rem;
}
.sky-fancy-input{
    width: 150px;
    border: 1px solid #cbcbcb;
    padding: .2rem;
    outline: none;
    /* height: 2rem; */
    background-color: white;
    border-radius: 2px;
   font-size: 1rem;
   /* margin:0.4rem 0rem 0.4rem 0rem; */
}
#fancy-one-container{
margin-left: 1rem;
}
.sky-bookmaker{
    width: 370px;
    border: 1px solid #cbcbcb;
    padding: .2rem;
    outline: none;
    /* height: 2rem; */
    background-color: white;
    border-radius: 2px;
   font-size: 1rem;
}
@media (max-width:768px) {
    .sky-fancy-content{
        flex-wrap: wrap;
       }
       .sky-fancy-container{
        width: 100%;
       }
       #fancy-one-container{
        margin: 0;
       }
       .refresh-fancy{
        margin-top: 1rem;
        margin-left: 0;
       }
}
@media   (min-width: 769px) and (max-width:867px) { 
   .sky-fancy-content{
    flex-wrap: wrap;
   }
   .sky-fancy-container{
    width: 80%;
   }
   #fancy-one-container{
    margin: 0;
   }
   .refresh-fancy{
    margin-top: 1rem;
    margin-left: 0;
   }
}

@media   (min-width: 868px)  and (max-width:1100px) { 
    
}