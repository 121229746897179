
.select-container{
    width: 32%;
}
.select-container select{
    width: 100%;
    border: 1px solid #cbcbcb;
    padding: .5rem;
    height: 2.9rem;
    outline: none;
    border-radius: .25rem;
    /* margin-top: .5rem; */
}

.event-head{
    color:  #0f93b4 ;
}
.ten{
    background-color: #00a5f7;
    color: white;
    border-radius: .25rem;
    padding: 0rem 0.5rem 0rem 0.5rem;
    text-align: center;
  
}
.zero{
   
    background-color: #008987;
    color: white;
    border-radius: .25rem;
    padding: 0rem 0.5rem 0rem 0.5rem;
    text-align: center;
    margin-left: 1rem;
}
/*  */

.check-container{
    font-size: 2rem;
   width: 2.5rem;
   height: 1.5rem;
   cursor: pointer;
   margin-top: .5rem;
}

.name-container {
    font-size: 1rem;
    font-weight: 600;
    color: #484551;
}

.date-container {
    color: #3ea79f;
    font-size: 1rem;
    font-weight: 600;
}
.main {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
  }
  
  
  .hello {
    margin: 30px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  .CalendarDay__selected_span {
    background: #82e0aa; 
    color: white; 
    border: 1px solid red;
  }
   
  
  .CalendarDay__selected {
    background: red;
    color: white;
  }
   
  
  .CalendarDay__selected:hover {
    background: orange;
    color: white;
  }
   
  
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: brown;
  }

  .scope-container{
    display: flex !important;
    justify-content: center;
    padding:0 !important;
    margin: 0 !important;
  }

  .scope-values{
    display:flex !important;
    flex-direction:column !important;
    padding:10px !important
  }

  @media (max-width:768px) {
    .select-container{
      width: 100%;
    }
  }
  @media   (min-width: 769px) and (max-width:867px) { 
   .select-container{
    width: 100%;
   }
}

@media   (min-width: 868px)  and (max-width:1100px) { 
   .select-container{
    width: 48%;
   }
}

.input-container.market-count {
  padding: 10px;
}
.input-container.market-count label {
  margin: 0 0 5px;
  display: block;
}
.input-container.market-count .market-count-input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;  
}
.update-league-btn.centerBtn {
  padding: 10px 20px;
  display: block;
  margin: 15px auto;
  width: max-content;
}
.tableRes {
  width: 100%;
  overflow: auto;
  padding: 0px !important;
}
.tableRes  tr td {
  text-wrap: nowrap;
}

.headingRes{
  text-wrap: nowrap;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0px;
  padding: 10px;
  color: rgb(19, 16, 16);
  border-radius: 5px;
}

.pagination button {
  padding: 10px 15px;
  border: none;
  background-color: #018990;
  color: #f0f0f0;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-size: 14px;
  color: #666;
}


.logPagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px;
  gap: 8px;
  padding: 10px;
  border-radius: 5px;
}

.logPagination-button {
  padding: 5px 15px;
  border: none;
  background-color: #11a88d;
  color: white;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
}

.logPagination-button:disabled {
  background-color: #d3d3d3;
  color: #808080;
  cursor: not-allowed;
}

.logPagination-input {
  width: 60px;
  text-align: center;
  border: 1px solid #dcdcdc;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.accordion:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.select-container .dp {
  max-width: 280px;
}

.result-sport-content .rsc {
  justify-content: flex-start;
  gap: 15px;
  align-items: flex-end;
}

.select-container .containerStyle .con {
  margin-left: 8px;
  max-width: 280px;
}

.market-section .ms {
  border-bottom: outset;
  padding: 8px;
}

.market-section .mktSc {
  border-bottom: groove;
  padding: 8px;
  margin: 0 -8px;
}