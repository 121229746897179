.league-container {
  display: flex;
  background-color: #f7f7f7;
  padding: 0.5rem;
  justify-content: space-between;
  border: 1px solid #cbcbcb;
  align-items: center;
}
.league-setting-container{
  background-color: #f7f7f7;
  padding: 0.5rem;
  border: 1px solid #cbcbcb;
}
.league-setting-content{
  display: flex;
  align-items: center;
  width: 70%;
  /* justify-content: space-between; */
}
.league-setting-btn{
  display: flex;
  align-items: center;
  margin: 0rem 1.5rem 0rem 1.5rem;
} 
.league-para{
  font-size: .875rem;
   display: flex;
   align-items: center;
}
.league-setting-body{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.toggle-btn-container{
  display: flex;
  align-items: center;
}
.league-detail-input{
  width: 140px;
  border: 2px solid #d6d6d6;
  border-radius: 2px;
  padding: .4rem;
  outline: none;
}
.league-setting-row{
padding-left: .5rem;
}
.para-three {
  display: flex; 
  width: 60%;
  align-items: center;
}
.accordion {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  /* width: 100%; */
  font-size: .875rem;
  outline: none;
  cursor: pointer;
  transition: 0.6s ease;
}
.accordion:hover,
.active {
  color: #c7576f;
  transform: all 0.5s ease;
}
.league_icon{
  margin-left: 4rem;
}
.league-setting-row label{
  font-size: .8rem;
}

.accordion__text {
  border-bottom: 1px solid #c6c6c6;
}

.match-accordian-body {
  display: flex;
  padding: .5rem;
  align-items: center;
  overflow-x: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 2px solid white;
}
.table-flex {
  display: flex;
  align-items: center;
  /* width: 10rem; */
}
.acc-head{
  /* color: red; */
}
.acc-head p {
  font-size: .75rem;
  color: #342f40;
  font-weight: 600;
  cursor: pointer;
  transition: 0.6s ease;
}
.acc-para p {
  font-size: .75rem;
  color: #000808;
  font-weight: 600;
}
.para-btn {
  background-color: #727271;
  font-size: 0.65rem;
  padding: 0rem 0.5rem 0rem 0.5rem;
  color: white;
  border-radius: 0.625rem;
  /* margin-right: 1rem; */
}
.ten-one {
  background-color: #727271;
  height: 1.5rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  padding: 0rem 0.2rem 0rem 0.2rem;
}
.league-accordian-container {
  background-color: #c6c6c6;
  scroll-behavior: smooth;
  transform: all 0.5s ease;
}
.market-setting-container{
  background-color: white;
}
.sub-accordian {
  background-color: #eeeeee;
  overflow: auto;
  transition: max-height 0.6s ease;
  border-radius: 2px;
}
.league-para-btn{
  display: flex;
  /* width: 1s0%; */
  align-items: center;
}
.update-league-btn{
  display: flex;
  background-color:  #018990;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  padding: .3rem .8rem .3rem .8rem;
}
.city-input{
  width: 110px;
  outline: none;
  padding: .2rem;
  border: 2px solid #d6d6d6;
  border-radius: 2px;
}
.market-accordian-body {
  display: flex;
  /* background-color: white; */
  align-items: center;
  margin-top: .3rem;
  width:100%;
  padding-left: .5rem;
  overflow-x: auto;
  
}
.fancy-setting-container{
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
.fancy-setting-body{
  background-color: #f7f7f7;
  padding: .5rem;
  border-top-right-radius: 4px;
  display: flex;
  border-top-left-radius: 4px;
  align-items: center;
  border-bottom: 2px solid #dbdbdb;
}
.active-match{
  color: #c13f42;
}
.fancy-setting-body h4{
  /* color: #c13f42; */
  text-transform: uppercase;
  cursor: pointer;
}
.fancy-setting-input{
  width: 100%;
  height: 2rem;
  border-radius: 4px;
  padding-left: .2rem;
  border: 2px solid grey;
  outline: none;
}
.fancy-setting-select{
  width: 100%;
  height: 2rem;
  border-radius: 4px;
  border: 2px solid grey;
  outline: none;
  font-size: 1rem;
}
.fancy-setting-table{
  background-color: white;
  padding: .5rem;
}
.fancy-setting-table thead th{
  font-size: .75rem;
  text-align: left;
  padding: .5rem;
}
.fancy-setting-table tbody tr{
  
  border-radius: 1px;
}
.fancy-setting-table tbody tr:nth-child(even){background-color: #f2f2f2}
.fancy-setting-table tbody tr td{
padding: .5rem;
}
.market-para{
  margin-left: .5rem;
  font-size: .785rem;
  font-weight: 600;
}

.match-left {
  margin-left: 4rem;
}

.match-input-1 input {
  width:198px;
  margin-left: 1rem;
  height: 2rem;
  padding-left: 0.2rem;
  border-radius: 4px;
  border: 2px solid grey;
  outline: none;
  
}
.match-input-1 label {
  font-size: 0.8rem;
  margin-left: 1rem;
}
.match-input input {
  width: 70px;
  height: 2rem;
  margin-left: 1rem;
  padding-left: 0.2rem;
  border: 2px solid #d6d6d6;
  outline: none;
  border-radius: 2px;
}

.coloumn-flex {
  display: flex;
  align-items: center;
}
.market-accordian-body td{
  padding: .5rem .5rem .5rem 0rem;
}

.no_data {
  padding: 1rem 0rem 1rem 1rem;
  font-size: 0.9rem;
}

@media (max-width:768px) {
  .league-setting-body{
    flex-direction: column;
  }
  .league-setting-content{
    width:100%;
    margin: 0;
  }
  .league-setting-btn{
    flex-direction: column;
    align-items: flex-start;
    margin: 0rem 0.3rem 0rem 0rem ;
  }
  .league-para{
align-items: flex-start;
/* margin-right: 1rem; */
  }
  .accordion {
    width: 100%;
  }
  .league-detail-input{
  margin: 0rem 1rem 0rem 0rem;
  }
}
@media   (min-width: 769px) and (max-width:867px) { 
  .league-setting-body{
    flex-direction: column;
  }
  .league-setting-content{
    width:100%;
    margin: 0;
  }
  .market-accordian-body{
    width: 100%;
  }
  .league-setting-btn{
    margin: 0rem 1rem 0rem 0rem;
  }
  .accordion {
    width: 100%;
  }
}

@media   (min-width: 868px)  and (max-width:1100px) { 
  .league-setting-body{
    flex-direction: column;
  }
  .league-setting-content{
    width:100%;
    margin: 0;
  }
  .league-setting-btn{
    margin: 0rem 1rem 0rem 0rem;
  }
  .market-accordian-body{
    width: 100%;
  }
  .accordion {
    width: 100%;
  }
}