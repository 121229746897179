
/******************************************************************************************/

.table-scrollable-container {
    width: 100%;
    overflow-x: auto;
    padding: 0 4px;
}

.row-even {
    background-color: #efefef;
}

.row-odd {
    background-color: #ffffff;
}

/******************************************************************************************/

.wrapper-head-st {
    background-color: #323a4f;
    color: #ffffff;
    padding: 10px;
}

.book-maker-market-mkt {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.boder-odds {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.market-odds-backs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-group {
    display: flex;
    gap: 10px;
}

.book-button, .bet-button {
    padding: 7px 14px;
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 10px;
    cursor: pointer;
}

.book-button {
    background-color: #0093dc !important;
}

.book-button {
    margin-right: 15px;
}

.book-button:focus {
    outline: none !important;
    box-shadow: none !important;
    border-style: none !important;
}

.bet-button {
    background-color: #f04c44;
}

.bet-button {
    margin-right: 25px;
}

.headingStyle {
    border: 1px solid #ddd;
    color: #ffffff;
    padding: 4px 6px;
    width: 50%;
    font-size: 1.1em;
    position: relative;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
    background: linear-gradient(to right, #dba43f, #e4c282, #dba43f);
}

.suspended-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.suspended-button {
    background-color: #fe3030;
    color: #fff;
    border-radius: 6px;
    border: none;
    padding: 6px 12px;
    cursor: not-allowed;
    margin: 8px 0px;
    font-size: 12px;
}

.odds-container-1 {
    display: flex;
}

.fancy-odds-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    /* margin: 10px; */
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    /* width: 50%; */
}

.category-title {
    background-color: #575655;
    color: white;
    margin: 10px 0;
    border-bottom: 2px solid #e4e5e7;
    padding: 5px 0;
    text-align: center;
    border-radius: 5px;
    font-size: medium;
}

.market-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid rgb(192, 187, 187);
}

.market-item {
    display: flex;
    background: white;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    width: 100%;
    gap: 10px;
}

.icon-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #1e1f20;
    color: white;
    font-size: 12px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3px;
}

.icon-button:focus {
    outline: none !important;
    box-shadow: none !important;
    border-style: none !important;
}

/******************************************************************************************/

.sportJsx-sup-centered {
    margin-left: auto;
}


.suspended-button-new {
    background-color: #fe3030;
    color: #fff;
    border-radius: 6px;
    border: none;
    padding: 6px 12px;
    cursor: not-allowed;
    font-size: 12px;
    font-weight: bold;
}

/******************************************************************************************/

.market-list.sportJsx-container {
    padding: 10px;
}

.sportJsx-wrap .back-box-container-one {
    margin-left: auto;
    position: relative;
}

.sportJsx-wrap .sportJsx-sup {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

.market-item.sportJsx-wrap {
    align-items: center;
    box-shadow: none;
    border: none;
    padding: 0px;
}

/* .market-item.sportJsx-wrap.boxhandleStyle{
    margin-left: auto;
    position: relative;
} */

.multiRunner-container {
    background-color: #2f85b0;
    color: #ffffff;
    border-bottom: 2px solid #e4e5e7;
    margin-bottom: 10px;
    padding: 5px 2px;
    text-align: center;
    border-radius: 5px;
    font-size: medium;
}

.market-item.sportJsx-wrap.multiRunner-container {
    border: 1px solid black;
}

.sportJsx-wrap-new {
    flex: 1 1 auto;
    padding: 10px;
    border: 1px solid rgb(225, 222, 222);
    margin-bottom: 10px;
    border-radius: 5px;
}

.sportJsx-wrap-new-full {
    align-items: center;
    gap: 10px;
}

/******************************************************************************************/

.under-over {
    display: flex;
    justify-content: end;
    align-items: center;
}

.underBox {
    margin-right: 0px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.overBox {
    margin-right: 20px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.underBox, .overBox:focus {
    outline: none !important;
    box-shadow: none !important;
    border-style: none !important;
}

.centered-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/******************************************************************************************/

.wrapper-body.bgstyle{
    background-color: rgb(5, 65, 70);
    color: rgb(255, 255, 255);
}

.ai-close.closeBtn-modal.crossBtn{
    color: rgb(255, 255, 255);
}

.hedStyle{
    background-color: #b0adad;
    text-align: center;
    white-space: nowrap;
}

.modal-content.widthMySty{
    width: 50%;
    min-height: none;
    max-width: 100%;
}

.fancyBet-wrap{
    width: 100%;
    background: white;
    padding: 15px;
    border-radius: 8px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/******************************************************************************************/

.flexContainer-wrap {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    flex-wrap: nowrap;
    padding: 10px;
}

.flexContainer-left, .flexContainer-right {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width:992px) {
    .flexContainer-wrap {
        flex-direction: column;
    }
}

/******************************************************************************************/

.book-button.eyeStyle{
    background: red !important;
    margin-right: 0px;
}