
/**************************************Client Statements***********************************/

.ai-close.closeBtn-modal.closeIcon{
    background-color: white;
    border-radius: 50%;
    color: red;
}

.linkButtonStyle {
    gap: 10px;
    background: white;
    border: none;
    color: rgb(50, 49, 49);
    cursor: pointer;
    font-size: 15px;
    padding: 10px;
    white-space: nowrap;
    transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;
    border: 1px solid rgb(190, 187, 187);
    margin: 10px 0px;
}

.linkButtonStyle:hover {
    color: red;
    text-decoration: underline;
    text-underline-offset: 4px; 
}

.linkButtonStyle:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid rgb(190, 187, 187) !important;
}

.linkButtonStyle.active {
    background-color: white;
    color: red;
    text-decoration: underline;
    text-underline-offset: 4px;
}