
.custom-btn{
    display: flex;
    justify-content: flex-end;
    text-align: end;
}
.custom-btn button{
    display: flex;
    justify-content: flex-end;
    text-align: end;
}
/* date picker styling  */

.react-datepicker {
    border: none;
    position: absolute;
    z-index: 3;
    /* left: 50%;
    top: 8rem; */
  }

.icon-flex{
    display: flex;
    align-items: center;
}
#edit-icon{
    margin-right: 1rem;
}


.add-sport-content{
    border: 2px solid #d2d2d2;
    border-radius: .25rem;
    padding: 1rem;
    margin: 1rem;
    position: relative;
}
.add-sport-input{
    width: 100%;
}

.add-sport-input select{
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #cbcbcb;
    font-size: 1rem;
    height: 2.5rem;
    outline: none;
    border-radius: .25rem;
    margin-top: .2rem;
}
.add-sport-input input{
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #cbcbcb;
    font-size: 1rem;
    height: 2.5rem;
    outline: none;
    border-radius: .25rem;
    text-transform: capitalize;
    margin-top: .2rem;
}
.sport-btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    /* width: 50%; */
}
.close-icon{
    width: 3rem;
    height: 2rem;
    background-color: grey;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.active-popup{
    animation: fade-in 1s 1 linear;
  animation-fill-mode: forwards;
  opacity: 0;
    display: block;
    right: 18rem;
    width: 50%;
    position: absolute;
    /* background-color: black; */
    top: 5rem;
    transition: 0.4s linear;
}
.add-field{
    display: flex;
    align-items: center;
    cursor: pointer;
}
#drop-input{
    overflow: auto;
    transition: max-height 0.6s ease;
    border-radius: 2px;
}

.uid_name{
    color: lightskyblue;
    cursor: pointer;
  }
  .status_button{
      background-color: #018990;
      padding:0.25rem;
      width: 5rem;
      text-align: center;
      border-radius: 8px;
      color: white;
      font-size: 14px;
  }
  .input-field {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100px;
    font-size: 14px;
    padding: 5px 0;
  }
  .input-field:focus, 
  .input-field:hover {
     border-bottom: 1px solid #000;
    background-color: #fff;
   
  }
  
  .manager_title{
      font-weight: 600;
      padding: 8px 4px;
      }
      @keyframes fade-in {
        0% {
          animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @media (max-width:768px) {
        .icon-flex{
            justify-content: flex-end;
        }
      }