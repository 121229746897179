.basic-button{
    height: 100%;
    width: 100%;
    z-index: 0;
    position: relative;
    border: none;
    color: #fff;
    padding: 0.5rem 1rem 0.5rem 1rem ;
    text-align: center;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}
.agent-btn-container.market-btn {
    margin-left: auto;
}
.agent-btn-container.market-btn .basic-button{
    height: auto;
}
/* colors  */
.submit{
    background-color: #018990;
}
.green{
    background-color: #0b8105;
}
.red{
    background-color: #f30909;
}
.yellow{
    background-color: #9bf944;
}
.cancel{
    background-color:#fe3030;
}