.checkbox {
    transform: scale(0.9);
    width: 3rem;
    height: 1.2rem;
    border-radius: 2px;
    background-color: #11a489;
   transition: all 0.5s;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .checkbox--off {
    flex-direction: row;
    color: white;
    background-color: #c6c6c6;
    border-radius: 2px;
    border: 1px solid black;
  }

  .disableButtons{
    color:white;
    background-color: #c6c6c6;
    border-radius: 2px;
    border: 1px solid black;
  }
  
  .checkbox__ball {
    background: whitesmoke;
    box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.72);
    width: 1.4rem;
    height: 0.9rem;
    border-radius: 2px;
    margin:0.2rem;
    margin-bottom: 0.25rem;
  }
  
  .checkbox__text {
    font-size: 1.8rem;
    margin-left: 5px;
    margin-right: 5px;
  }
  