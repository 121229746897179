.agentGetMarket .user-agent-1 {
    display: flex;
    align-items: center;
    gap: 5px;
}
.agentGetMarket .modal {
    font-size: 18px;
    padding: 0px;
}
.agentGetMarket .top-wrapper-agent {
    display: none;
}
.agentGetMarket .wrapper {
    padding: 0px;
}
.agentGetMarket .header-right-top .span-book p {
    font-size: 14px;
}
.agentGetMarket .header-right-top .span-book .match-name {
    font-size: 13px;
    letter-spacing: 0;
}

.pageMain-container {
    /* margin: -.5rem; */
    margin: 0px;
    padding: 0px;
    width: auto;

}

.pageMain-wrap {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 360px;
    max-width: 100%;
    gap: 10px;
    background: #f0f0f0;
    background: #ffffff;
}
.bookMaker-header {
    background-color:#F0F0F0 ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #dadada75;
}  
.pageMain-left {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.pageMain-right {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.filter-container {
    border: 1px solid #dadada75;
}
.filter-title-wrap {
    background-color:#F0F0F0 ;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    gap: 8px;
}
.filter-title {
    font-size: 14px;
    font-weight: 600;
}
.betsCount {
    background: #313131;
    color: #fff;
    padding: 2px 4px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    border-radius: 4px;
}
.filter-wrap {
    display: flex;
    padding: 10px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}
.filter-icon {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    margin-right: auto;
}
.filter-icon svg {
    width: 16px;
    height: 16px;
}
.filter-live {
    background: #ffffff;
    color: #313131;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}
.filter-live span {
    margin: 0px;
    background: green;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    animation: blinkMe 1s infinite;
}
.filter-live.isLive {
    color: green;
    background: #00800026;
}
.filter-live.isNotLive {
    color: red;
    background: #ff000026;
}

.filter-live.isLive span {
    background: green;
}
.filter-live.isNotLive span {
    background: red;
}
.filter-clear {
    background: #c7c7c7;
    color: #313131;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}
.filter-clear.filterOn {
    background: red;
    color: #fff;
}

@keyframes blinkMe {
    50% {
        opacity: 0.0;
    }
}

.market-dataWrap {
    flex: 1 1 auto;
    border: 1px solid #dadada75;
}
.pageMain-wrap .bookMaker-header .span-book {
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
}
.pageMain-wrap #bookmaker-select {
    flex: 1 1 auto;
    width: fit-content !important;
    margin: 0px;
    max-width: 120px;
}
.pageMain-wrap #bookmaker-select.widthFix {
    max-width: 150px;
}
.pageMain-wrap #bookmaker-select p {
    font-size: 14px;
    font-weight: 500;
    color: #646464;
    text-wrap: nowrap;
    margin: 0 0 5px;
}
.pageMain-wrap .bookMaker-header .gridCrad-box {
    gap: 20px;
}
.pageMain-wrap .bookMaker-header .gridCrad-box #bookmaker-select {
    flex: 0 0 auto;
    max-width: max-content;
}
/* .pageMain-wrap .bookMaker-header .flex-data {
    display: flex;
    align-items: center;
    gap: 10px;
} */
.pageMain-wrap #bookmaker-select.flex-data p {
    margin-bottom: 5px;
}
.pageMain-wrap .bookMaker-container {
    border: 1px solid #dadada75;
}
.pageMain-wrap .bookMaker-header .book-maker-column-input {
    width: 100%;
    max-width: 130px;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
}
.pageMain-wrap .bookMaker-header .book-maker-column-input.width100 {
    max-width: 240px;
}

.pageMain-wrap .bookMaker-header .submit {
    flex: 0 0 auto;
    width: auto;
    align-self: center;
}
.bookMaker-body {
    background: #ffffff;
    padding: 10px;
}
.bookMaker-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.pageMain-wrap .boder-odd, .pageMain-wrap .book-maker-market {
    padding: 0px;
    margin: 0px;
}
.pageMain-wrap .market-book-maker-border {
    margin: 0px;
}
.pageMain-wrap .market-odds-body {
    padding: 5px 10px;
    background: #f7f7f7;
    border-bottom: 1px solid #dadada75;
}
.pageMain-wrap .market-odds-body p {
    font-size: 12px;
    font-weight: 700;
}
.pageMain-wrap .market-odds-body .market-odds-back {
    margin-right: 0px;
    width: 140px;
    gap: 8px;
}
.pageMain-wrap .market-odds-body .market-odds-back div {
    flex: 1 1 auto;
    min-width: 62px;
    text-align: center;
    margin: 0px !important;
}
.bookMaker-grid .bookMaker-table, .bookMaker-grid .boder-odd {
    border: 1px solid #dadada75;
}
.bookMaker-grid .bookMaker-table, .bookMaker-grid .boder-odd .boder-odd {
    border: none;
}
.pageMain-wrap .boder-odd .suspend-boxx {
    font-size: 14px;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    padding: 8px 15px;
}
.pageMain-wrap .boder-odd .suspend-box {
    font-size: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 15px;
}
.pageMain-wrap .boder-odd .odds-container {
    padding: 5px 10px;
}
.pageMain-wrap .boder-odd .odds-container .box-odd-name {
    padding: 0px;

}
.pageMain-wrap .boder-odd .odds-container .box-odd-name h3 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    padding: 0px;
}

.pageMain-wrap .boder-odd .odds-container .odds-container-1 {
    margin-right: 0px;
    width: 140px;
    gap: 8px;    
}
.pageMain-wrap .boder-odd .odds-container .odds-container-1 .back-box-container-one {
    flex: 1 1 auto;
    min-width: 62px;
    text-align: center;
}
.pageMain-wrap .boder-odd .odds-container .odds-container-1 .back-box-container-one div {
    margin: 0px;
    height: auto;
    width: 100%;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    border-radius: 4px;
    text-align: center;
}

.bookMaker-body .bookMaker-table {
    padding: 0px;
    border: none;
    max-height: 280px;
    overflow: auto;
}
.bookMaker-body .bookMaker-table.marginBottom {
    margin-bottom: 10px;
}
.bookMaker-body .bookMaker-table th {
    background: #f7f7f7;
    position: sticky;
    top: 0;
    font-weight: 700;
    font-size: 12px !important;
    padding: 5px !important;
    text-wrap: nowrap;
}
.bookMaker-body .bookMaker-table #fancy-head-coloumn th {
    border-bottom: 0px;
}

.bookMaker-body .bookMaker-table::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
.bookMaker-body .bookMaker-table table .custom-row th, .bookMaker-body .bookMaker-table table .custom-row td {
    font-size: 12px;
    padding: 5px;
}
.bookMaker-body .bookmaker-table-container {
    padding: 0px;
    margin: 0px;
    border: none;
    overflow: auto;
}
.bookMaker-body .bookmaker-table-container #fancy-head-coloumn th {
    font-size: 12px;
    line-height: 18px;
    padding: 5px;
}
.bookMaker-body .bookmaker-table-container th, 
.bookMaker-body .bookmaker-table-container td {
    border: 1px solid #dadada75 !important;
}
.bookMaker-body .bookmaker-table-container td .book-maker-column-input, .bookMaker-body .bookmaker-table-container td .book-maker-column-input-1 {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    padding: 8px;
}
.bookMaker-heading p {
    font-size: 16px;
    font-weight: 600;
}
.bookMaker-heading.spaceAdd p {
    margin-bottom: 8px;
}
.bookMaker-body .book-maker-content {
    padding: 0px;
}
.bookMaker-body .book-maker-select-container {
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 20px;
}
.bookMaker-body .book-maker-select-container .book-maker-select{
    min-width: 280px;
    max-width: 400px;
    width: auto;
    flex:  0 0 auto;
}
.bookMaker-body .book-maker-select-container .book-maker-select label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #646464;
    margin-bottom: 5px;
}
.bookMaker-body .book-maker-select-container .book-maker-select select {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    padding: 8px;
    font-weight: 500;
    height: 40px;
}
/* .bookMaker-body .react-datepicker__input-container {
    max-width: 200px;
} */
.bookMaker-body .react-datepicker__input-container .fancy-stake-input {
    width: 200px;
}
.input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.react-datepicker{
    width:278px
}

.agentSettingMdl>div>div {
    max-width: 650px !important;
}
