.error-container{
    width: 100%;
    background-color: #ede7e7;
    height: 100vh;
}
.error-body{
    display: flex;
    justify-content: center;
}
.error-head{
    margin-top: 10rem;
}
.error-head h1{
    font-size: 10rem;
    letter-spacing: 2rem;
}
.error-head p{
    font-size: 2rem;
    text-align: center;
}
.error-btn-container{
    margin-top: 1rem;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}
.error-head button{
    border: none;
    outline: none;
    border-radius: 2rem;
    /* width: 100%; */
    padding: 1rem 2rem 1rem 2rem;
    text-align: center;
    font-size: 1rem;
    background-color: black;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: white;
}