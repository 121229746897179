
.match-odd-container{
    margin: 1rem;
}
.match-odd-head{
    display: flex;
    background-color:#e5e5e5;
    padding: .2rem 1rem .2rem 1rem;
    display: flex;
    border-radius: 4px;
    justify-content: space-between;
}
.match-odd-body{
    display: flex;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 0rem 1rem 0rem 1rem;
    justify-content: space-between;
    align-items: center;
    /* align-items: center; */
}
.match-back-right{
    width: 420px;
    display: flex;
    justify-content: center;
}
.min-max-match{
    width: 420px;
    display: flex;
    justify-content: flex-end;
    /* border-top: 1px solid #dbdbdb; */
}
.match-back-right span{
    display: flex;
    justify-content: center;
    width: 70px;
}
.match-odd-card{
    width: 435px;
}
.runners-name:nth-child(2){
    margin-top: 3rem;
}
.runners-name:nth-child(1){
    margin-top: 0rem;
}
.runners-name:nth-child(3){
    margin-top: 3.5rem;
}
.match-odds-back-lay{
    display: flex;
}
.match-odds-back-lay-content{
    display: flex;
    margin-top: 1rem;
   border-bottom: 1px solid #dbdbdb;
   padding-bottom: .5rem;
}
.odd-price{
    font-weight: 700;
    font-size:15px;
    font-family: "Roboto" sans-serif;
    width: 100%;
    height: 50%;
    text-align: center;
    /* line-height: 33px; */
}
.odd-price-1{
    font-size: 10px;
    font-weight: 400;
    width: 100%;
    height: 50%;
    text-align: center;
}
@media (max-width:768px) {
    .match-back-right{
        width: 140px;
    }
    
}