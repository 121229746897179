.dashboard{
    display: flex;
    justify-content: center;
    align-items: center;
}
.userName{
    color:#018990 ;
}
.userName-1{
  color: white !important;
}
.status{
    font-size: 2rem;
}
.header-logo{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.header-logo-img{
    display: flex;
    width: 196px;
    align-items: center;
    height: 2.8rem;
    margin-left: 1rem;
    cursor: pointer;
  }
 img{
  width: 100px;
 }
  .menu-btn{
    display: none;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .menu-icon{
    font-size: 1.8rem;
    color: white;
    display: none;
  }
  .header-right{
   width: calc(100% - 178px);
   transform: all 0.5s ease;
   
  }
  .header-agent-logo{
    display: flex;
    margin-left: .6rem;
  }
  
  .header-right-top{
    width: 100%;
    display: flex;
   transform: all 0.5s ease;
   align-items: center;
   background:#054146;
   padding: .5rem;
   justify-content: space-between;
  }
  .top-nav{
  background-color: white;
  height: 50px;
  width: 100%;
  display: flex;
  padding: 0.2rem 0.5rem 3.3rem 0rem;
  justify-content: flex-end;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0 !important;
  z-index: 1000;
  }
  
  .refresh {
    text-align: center;
    margin: 0rem 0.5rem 0rem 0.5rem;
    cursor: pointer;
  }
  .user-agent{
    text-align: center;
    margin: 0rem 0.5rem 0rem 0.5rem;
    cursor: pointer;
  }
  .user-agent-1{
    text-align: center;
    margin: 0rem 0.5rem 0rem 0.5rem;
    cursor: pointer;
    color: white;
  }
  .top-wrapper .sidebar{
    background: #054146;
    width: 178px;
    height: 100vh;
    /* padding: 10px 0; */
    transition: all 0.5s ease;
    /* overflow-x: hidden; */
    position: fixed;
  }
  .top-wrapper-user .header-user{
    width: 100%;
    padding: 10px 2px;
    background: #054146;
    transition: all 0.5s ease;

  }
  .top-wrapper-agent .sidebar-agent{
   
    width: 100%;
    padding: 10px 2px;
    transition: all 0.5s ease;
  }
  
  .side-container {
    display: flex;
    justify-content: space-between;
  }
  
  .side-left ul {
    list-style: none;
    padding-bottom: .5rem;
    overflow-y: auto;
    height: 94vh;
  }
  .children-container {
    overflow-y: auto;
    height: 92vh;
  }
  .quick-link {
    color: white;
  }
    .tablink {
      display: flex;
      align-items: center;
      width: 11rem;
      padding: 0.5rem;
      border-bottom: 1px solid #10558d;
      color: rgb(241, 237, 237);
      font-size: .875rem;
      position: relative;
      cursor: pointer;
  }
  .tablink:hover{
    color: #054146;
    background:white;
    border-right: 2px solid rgb(5, 68, 104);
  }
  .active-side {
    color: #054146 !important;
    background:white;
    border-right: 2px solid rgb(5, 68, 104);
  }
  .active-side-1 {
    color: white !important;
    background:#054146;
    border-right: 2px solid rgb(5, 68, 104);
  }
  .logout {
    font-size: 1.5rem;
  }
  
  .side-top{
    width: 100%;   
   display: flex;
   background-color: #dbdbdb;
  }
  .tablink-agent{
    display: flex;
    align-items: center;
    font-size: .875rem;
    position: relative;
    width: 10rem;
    cursor: pointer;
    color:  #054146;
    padding: .8rem;
  }
  .tablink-agent-1{
    display: flex;
    align-items: center;
    font-size: .875rem;
    position: relative;
    width: 10rem;
    cursor: pointer;
    color:  #054146;
    padding: .8rem;
  }
  .tablink-agent-1:hover{
    color: white !important;
    background-color:#054146;
   }
  .modal {
      display: flex;
      align-items: center;
      font-size: .875rem;
      position: relative;
      cursor: pointer;
      color: #054146;
      padding: .8rem;
      cursor: pointer;
      color: rgb(0, 123, 255);
      text-decoration: underline;
  }
 
  @media (max-width:768px) {
    .side-container{
        flex-direction: column;
    }
    .top-nav{
      background-color: transparent;
      position: absolute;
      box-shadow: none;
      z-index: 3;
      width: 30%;
      top: 7px;
      right: 0;
    }
    .tablink {
      text-align:left;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0;
    }
    .top-wrapper .sidebar{
        height: auto;
        width: 100%;
        position: relative;
        top: 0;
        bottom: 0;
        display: flex;
        padding: 5px 4px 5px 4px;
        z-index: 3;
    }
    .header-agent-logo{
      margin-left: 2.5rem;
      position: absolute;
      top: 14px;
    }
    .user-agent{
      display: flex;
      position: absolute;
      flex-direction: column;
      align-items: center;
      right: 0;
      top: 3px;
    }
    .refresh{
      position: absolute;
      z-index: 3;
      display: flex;
      margin: 0;
      flex-direction: column;
      align-items: center;
      /* align-items: center; */
    }
    .user-icon{
      fill: white;
      font-size: 1.2rem;
    }
    .side-left{
      display: none;
    }
    .side-top{
      padding: 0;
      display: none;
    }
    .userName{
     color:#018990;
    }
    .name{
      display: none;
    }
    .header-logo{
      flex-direction: row-reverse;
      margin: 0;
      justify-content: flex-end;
      padding-left: 0rem;
    }
    .header-logo-img{
      margin-left: 0rem;
      /* margin-top: 0.2rem; */
      height: 2.9rem;
    }
    .user-logo{
      margin-left: 1rem;
    }
    .menu-btn{
      display: block;
    }
    .menu-icon{
      display: block;
    }
    .top-wrapper-agent .sidebar-agent{
      padding: 10px 2px;
    }
    .active-menu{
      position:absolute;
      left: 0rem;
      top: 3.1rem;
      display: flex;
      z-index: 4;
      display: block;
      overflow-x: auto;
      width: 100%;
      background: #054146;
    }
    .tablink-agent{
      width: 100%;
    }
   
    .active-menu-agent{
      position:absolute;
      left: 0rem;
      top: 3rem;
      display: flex;
      z-index: 4;
      display: block;
      overflow-x: auto;
      width: 100%;
      background: rgb(5, 68, 104);
    }
    .header-right{
       margin: 0;
       width: 100%;
    }
  }
 /*
  @media   (min-width: 769px) and (max-width:867px) { 
    .header-right{
      margin: 0;
      width: 79%;
  }
}

@media   (min-width: 868px)  and (max-width:1100px) { 
  .header-right{
    margin: 0;
    width: 80%;
}
}
*/