
.agent-btn-container{
  display: flex;
  align-items: center;
  /* margin-top: 1rem; */
}
.change-password-container{
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.declare-result{
  --min:40ch;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.declare-result>*{
  flex: 1 1 var(--min);
}
.print-btn{
  background-color: #018990;
  padding: .4rem 1rem .4rem 1rem;
  text-align: center;
  cursor: pointer;
  height: 2.5rem;
  border-radius: 2px;
  color: white;
  margin-right: 1rem;
}
.edit-btn{
  background-color: #018990;
  border-radius: .25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  cursor: pointer;
  height: 2rem;
  border: none;
}
.user-btn-action{
  display: flex;
  align-items: center;
}
.delete-btn{
  margin-left: .5rem;
}
.add-agent-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  /* background-color: #dbdbdb; */
  padding: .5rem 1rem .5rem 1rem;
}
.agent-add-form{
  padding: .5rem 1rem .5rem 1rem;
}
.agent-input-body{
  width: 100%;
  margin-top: .2rem;
  padding: 0.2rem .5rem 0.2rem .5rem;
}
.agent_input{
  width: 100%;
  border: 2px solid #cbcbcb;
  padding: .5rem;
  outline: none;
  height: 2.3rem;
  background-color: white;
  border-radius: .25rem;
  font-size: 1rem;
}
.agent-permission{
  border: 1px solid #dbdbdb;
margin-top: 1rem;
background-color: #f7f7f7;
padding: .5rem;
border-top-left-radius: .625rem;
border-top-right-radius: .625rem;
}
.agent-declare-result{
  background-color: white;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  padding: .5rem;
}

.agent-password-container{
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 1rem;
}
.password-input{
  width: 200px;
  border: 2px solid #cbcbcb;
  padding: .5rem;
  outline: none;
  height: 2.5rem;
  background-color: white;
  border-radius: .25rem;
  font-size: 1rem;
  margin-right: 1rem;
}
.ai-close{
  font-size:1.2rem;
  cursor:pointer;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  color: black;
}
.ai-close:hover{
  background-color: #dbdbdb;
  border-radius: 2rem;
}
@media (max-width:768px) {
  .agent-password-container{
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
  }
  .password-input{
  width: 100%;
  }
  .agent-btn-container{
    margin-top: 1rem;
  }
 .print-btn{
  margin-top: 0rem;
 }

}

.scope-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}
.scope-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.scope-keys {
  display: contents;
}

.scope-key {
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}

.scope-values {
  display: contents;
}

.scope-value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  position: relative;
  left: 50px;
  text-transform: capitalize;
}

.scope-value input[type="checkbox"] {
  margin-right: 10px;
}

.ip-address-form .input-container {
  display: grid;
  gap: 8px;
  align-items: flex-end;
}
.ip-address-form .input-container .add-ip-btn {
  height: 2.3rem;
  max-width: 100px;
  font-size: 1rem;
  color: #fff;
  background-color: #018990;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
}
.ip-address-form .input-container .add-ip-btn:disabled, .ip-address-form .input-container .add-ip-btn.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
.multiSelect-inp-wrap, .multiSelect-label-wrap {
  display: flex;
}
.multiSelect-inp-wrap input, .multiSelect-label-wrap label {
  flex: 1 1 auto;
  width: 50%;
}
.multiSelect-divide {
  width: 1px;
  flex: 0 0 4px;
  background: #cbcbcb;
}
.multiSelect-wrap {
  width: 100%;
  border: 2px solid #cbcbcb;
  outline: none;
  background-color: white;
  border-radius: .25rem;
  font-size: 1rem;
  min-height: 2.3rem;
}
.multiSelect-wrap .agent_input {
  width: 100%;
  border: none !important;
  padding: .5rem;
  outline: none !important;
  height: auto !important;
  background-color: white;
  border-radius: .25rem;
  font-size: 1rem;
}
.ip-address-form .input-container .selected-ip-container {
  border-top: 2px solid #cbcbcb;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  max-height: 94px;
  overflow: auto;
}
.ip-address-form .input-container .selected-ip-container .ip-item {
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #d6d6d6;
}