@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,200;1,300&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700|Sanchez&display=swap");
@import 'https://fonts.googleapis.com/css?family=Roboto:100,300';
@import 'https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.3.2/css/simple-line-icons.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css';
@import 'https://fonts.googleapis.com/css?family=Roboto+Mono:300,700';
@import './pages/user/user.css';
@import './pages/downline/downline.css';
@import './pages/result/result.css';

:focus {
  border-style: solid !important;
  border-width: 2px;
  border-color: rgba(255, 47, 0, 0.951) !important;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  width: 100%;
  /* background: linear-gradient(90deg, #92a8d1 20%,  #f7cac9 100%); */
  font-family: 'Roboto Mono';
  letter-spacing: 1px;
}
a{
  text-decoration: none;
}
.no-data-coloumn{
  border-bottom: 2px solid #d2d2d2;
  padding:0.2rem 0.5rem 0.2rem 0.5rem;
}
.wrapper{
  background-color:#F0F0F0;
  padding: .5rem ;
  /* height: 100vh; */
}
.accordion:focus {
  color: red;
}
tr.back.custom-row {
  background-color: #8dd2f0 !important;
}
tr.lay.custom-row {
  background-color: #feafba !important;
}

.admin-password{
  /* margin-top: 1.5rem;
   */
   display: flex;
   align-items: center;
}
.admin-pass-btn{
  margin-right: 3rem;
  margin-top: 1rem;
}
.admin-pass-btn button{
  padding: .3rem .5rem .3rem .5rem;
  cursor: pointer;
  border: none;
  border-radius: .25rem;
  color: white;
  outline: none;
  background-color: #018990;
}
.wrapper-head{
  background-color: #dbdbdb;
  padding: 0.5rem;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchcard {
  width: 16rem;
    border: 1px solid #cbcbcb;
    padding: .5rem;
    outline: none;
    height: 2.5rem;
    display: flex;
  background-color: white;
    align-items: center;
    /* background-color: #dbdbdb; */
    border-radius: .25rem;
    font-size: 1rem; 
}
.searchcard input {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  color: black;
}

.delete-icon{
  background-color: #018990;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.DayPicker_transitionContainer 
{
  height: 380px !important;
}
.table-border{
  border: 2px solid #d2d2d2;
  margin-top: 0.5rem;
  border-bottom: none;
  border-radius: .25rem;
  background-color: white;
  
}

.sport-container{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.agent-modal{
  width: 40%;
  max-height: 540px;
  background-color:white;
  padding-bottom: 1rem ;
 border-radius: 2px;
overflow-x: hidden;
box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
}
.modal-container-one{
  background-color:white;
padding-bottom: 1rem ;
max-height: 540px;
/* position: absolute;
top: 35%;
left: 50%; */
border-radius: 2px;
/* //transform: translate(-50%, -50%); */
width: 30%;
overflow-x:hidden;
box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
}
.modal-container-two{
  background-color:white;
padding-bottom: 1rem ;
max-height: 405px;
position: absolute;
top: 35%;
left: 50%;
border-radius: 2px;
transform: translate(-50%, -50%);
width: 35%;
overflow-x:hidden;
box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
}

table{
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;

}
table .custom-row {
  background-color: #ffffff;
}

table .custom-row th,
table .custom-row td {
  text-align: left;
  border-bottom: 2px solid #d2d2d2;
  /* padding:0.2rem 0.5rem 0.2rem 0.5rem; */
  font-size: 14px;
  padding: 8px;
}

table .custom-row td label {
  margin-bottom: 5px;
  white-space: nowrap;
  display: inline-block;
}

table .custom-row-result th,
table .custom-row-result td {
  text-align: left;
  border-bottom: 2px solid #d2d2d2;
  padding:0.2rem 0.5rem 0.2rem 0.5rem;
  font-size: 14px;
  white-space: nowrap;
  width: 25px;
}

table .custom-row th {
  font-size: .85em;
  text-transform: uppercase;
}

.wrapper-body{
    background-color: #f4f4f4;
    padding: .5rem;
 border-bottom: 1px solid #c4c0c0;
 border-top-left-radius: .25rem;
 border-top-right-radius: .25rem;
}

.wrapper-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wrapper-content-1{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.manage-event-body h3{
    font-size: 1.25rem;
}
.tab-container{
  display: flex;
  align-items: center;
  list-style: none;
}
.tab-box{
  background-color: white;
  color: black;
  /* width: 6rem; */
  cursor: pointer;
  padding: .5rem;
  border-radius: .25rem;
  margin: 0rem 1rem 0rem 0rem;
}

.no-data{
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.5rem;
  white-space: nowrap;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
ul{
  list-style: none;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media (max-width:768px) {
 
  .tab-box{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;
  }
  body {
    width: 100%;
    /* background: linear-gradient(90deg, #92a8d1 20%,  #f7cac9 100%); */
  }
  .wrapper-content{
    flex-direction: column;
  }
  .agent-modal{
    width: 80%;
    max-height: 585px;
  }
  .modal-container-one{
    width: 70%;
  }
  table .custom-row th,
table .custom-row td {
  font-size: 0.8rem;
}
}
@media (min-width:769px) and (max-width:1100px) {
  .modal-container-one{
    width: 50%;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.modal-container-one.leagueStyle {
  overflow: visible;
}

.modal-container-one.leagueStyle .DayPicker_transitionContainer {
  height: 330px !important;
}

.modal-container-one.leagueStyle .DateRangePicker_picker {
  z-index: 99;
}

.modal-container-one.leagueStyle .DayPickerKeyboardShortcuts_show {
  display: none;
}

.leagueStyle .selectWrap-main [class$="control"] > div:first-child {
  max-height: 98px;
  overflow: auto !important;
}

.containerStyle .DateRangePicker {
  width: 100%;
  margin-top: 0.2rem;
}

.containerStyle .DateRangePickerInput {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid #dbdbdb !important;
  overflow: hidden;
}

.containerStyle .DateInput .DateInput_input {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: hsl(0, 0%, 50%);
  padding: 10px;
  line-height: 18px;
  border: none !important;
  text-align: center;
}

.containerStyle .DateRangePickerInput .DateInput {
  flex: 1 1 auto;
}

.containerStyle * {
  border: none !important;
}

.link-button {
  background: none;
  border: none;
  color: rgb(29, 150, 225);
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.link-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border-style: none !important;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.closeBtn-modal {
  display: block;
  margin-left: auto;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.flex-wrap-between {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0px 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.table-border-1 th, .table-border-1 td {
  border: 1px solid #d5d5d5;
  border-collapse: collapse;
}

.table-responsive {
  flex: 1 1 auto;
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-responsive  {
  border: 1px solid #d5d5d5;
  border-collapse: collapse;
}

.copy-buttons {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
  transition: color 0.3s ease;
}

.copy-buttons:hover {
  color: #0056b3;
}

.copy-buttons:focus {
  outline: none !important;
  box-shadow: none !important;
  border-style: none !important;
}

.copy-buttons:active {
  transform: scale(0.95);
}

.text-center {
  text-align: center !important;
}

.w-33 {
  width: 33.33% !important;
}

.redDelete-btn {
  background-color: #f00 !important;
}

.result-inputBorder:focus {
  outline: none !important;
  box-shadow: none !important;
  border-style: none !important;
}

.result-input:focus, .custom-btn button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid rgb(156, 155, 155) !important;
  border-radius: 3px;
}

.custom-btn button {
  outline: none !important;
}

.tableResponsive-wrap.borderStyle table {
  border-collapse: collapse;
  width: 100%;
}

.tableResponsive-wrap.borderStyle th, .tableResponsive-wrap.borderStyle td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.tableResponsive-wrap.borderStyle th {
  background-color: #c6c3c3;
}

.odds-gap-table-client thead tr {
  background-color: #d1cfcf;
  color: #000000;
}

.odds-gap-table-client tbody tr {
  border-top: 1px solid #ccc;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.link-button-sta {
  background: none;
  border: none;
  color: rgb(29, 150, 225);
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.link-button-sta:focus {
  outline: none !important;
  box-shadow: none !important;
  border-style: none !important;
}

.user-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 2px;
  border: 2px solid #ddd;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.info-box {
  padding: 2px 4px;
  background-color: #0a0d0a;
  border-radius: 4px;
  color: #f0f0f0;
  min-width: 100px;
  text-align: center;
  margin: 10px 0;
  border: 1px solid #050705;
}

.info-box.locked {
  background-color: red;
  color: white;
  border: none;
  padding: 3px 6px;
}

/* .button-container {
  display: flex;
  gap: 10px;
  margin-left: auto;
  justify-content: flex-end;
} */

.exposure-btn {
  background-color: #018990;
  color: white;
  border: 2px solid #018990;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
}

.tree-container {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
}

.hierarchy-wrap {
  width: 100%;
  display: flex;
}

.hierarchy-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: fit-content;
  max-width: 100%;
  white-space: pre-wrap;
}

.hierarchy-text {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
  line-height: 1.5;
  white-space: pre;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #0b0c0d;
  transition: color 0.3s ease;
}

.copy-button:hover {
  color: #1d2227;
}

.copy-button:active {
  transform: scale(0.95);
}

.copy-button svg {
  height: 17px;
  width: 20px;
  margin-left: 10px;
}

.copy-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border-style: none !important;
}

.hierarchy-label {
  font-weight: bold;
  font-size: 16px;
  color: #101f07;
  margin-bottom: 5px;
}

.hierarchy-line {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  line-height: 1.5;
}

/* 
.hierarchy-container {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.hierarchy-modal {
  position: fixed;
  background: #fff;
  padding: 15px;
  border-radius: 12px;
  width: 380px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.hierarchy-header {
  display: flex;
  justify-content: flex-end;
}

.hierarchy-close-btn {
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  line-height: 1;
  background: #da0000;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
}

.hierarchy-close-btn:hover, 
.hierarchy-close-btn:focus, 
.hierarchy-close-btn:focus-visible {
  background: #da0000;
  color: #fff;
  border: none;
}

.hierarchy-table-container {
  margin-top: 15px;
  max-height: 434px;
  overflow: auto;
}

.hierarchy-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
  background: #ffffff;
  border-radius: 8px;
}

.hierarchy-table td {
  border: 0px solid #ccc;
  padding: 14px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  padding: 8px;
  line-height: 1;
}

.hierarchy-table tr:not(:last-child) td {
  border-bottom: none;
}

.hierarchy-table tr:not(:last-child) td::after {
  content: "↓";
  display: block;
  font-size: 1.5rem;
  color: #007bff;
  margin-top: 5px;
  font-weight: bold;
} */


.league-setting-container-newCon{
  background-color: white;
  padding: none;
  border: 1px solid #cbcbcb;
}

.market-section .sty {
  border-bottom: outset;
  padding: 10px;
}

.wrapper-body-sty {
  padding: .5rem;
  border-bottom: 1px solid #c4c0c0;
}

.react-datepicker-wrapper {
  position: relative;
  border: 1px solid #cbc8c8 !important;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.custom-date-picker {
  cursor: pointer;
  text-align: center;
  width: 220px;
  font-size: 15px;
  color: hsl(0, 1%, 21%);
  padding: 10px;
  line-height: 17px;
  border-radius: 4px;
}

.react-datepicker__input-container input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.datePicker {
  cursor: pointer;
  text-align: center;
  width: 220px;
  font-size: 15px;
  color: hsl(0, 1%, 21%);
  padding: 10px;
  line-height: 17px;
  border-radius: 4px;
  display: flex;
}

.react-datepicker {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
}

.react-datepicker__month-container{
  width: 100%;
}

.react-datepicker__header {
  background-color: #007bff !important;
  color: white;
  border-bottom: none;
  width: 100%;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: white !important;
  font-weight: bold;
}

.react-datepicker__navigation--previous {
  left: 2px;
  margin-top: 8px;
}

.react-datepicker__navigation--next {
  right: 2px;
  margin-top: 8px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  display: none !important;
}