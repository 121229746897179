.fancy-market-container{
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    display: flex;
    padding: .5rem;
    justify-content: center;
    margin: 2rem 1rem 1rem 1rem;
}
.fancy-market-head{
    display: flex;
    align-items: center;
}
.fancy-tab{
    background-color: #054146;
    color: white;
    border: 1px solid #054146;
  padding: 0.2rem 2rem .2rem 2rem;
  cursor: pointer;
}
.fancy-active{
    background-color: white;
    border: 1px solid rgb(62, 181, 194) ;
    color: rgb(62, 181, 194);
    
}
.all-fancy-market-border{
    border: 1px solid #dbdbdb;
    margin: 1rem;
    padding: .5rem;
}
.fancy-market-content{
    /* width: 50%; */
    cursor: pointer;
    padding: .2rem;
    border-radius: 4px;
    margin: .5rem 0rem .5rem 0rem;
    border: 1px solid #dbdbdb;
}
.icon-fancy{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fancy-accordian-content{
    border: 1px solid #dbdbdb;
    padding: .5rem;
    border-radius: 4px;
}
.fancy-accordian-content p{
    text-align: center;
}
.content-fancy-odds{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 1rem;
}
.fancy-back-lay-container{
    width: 13%;
    position: relative;
    /* justify-content: flex-end; */
    align-items: center;
    flex-wrap: wrap;
    display: flex;
}
.fancy-suspended{
    display: flex;
    align-items: center;
    position: relative;
    
}

.suspend-fancy{
    position: absolute;
    font-size: 17px;
    color: #df1717;
    right: 1.8rem;
    text-transform: uppercase;
    font-weight: 800;
}

@media (max-width:768px) {
  .fancy-back-lay-container{
    width: 50%;
  }    
}
@media (min-width:769px) and (max-width:1100px) {
   .fancy-back-lay-container{
    width: 20%;
   }
  }
  