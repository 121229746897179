.event-container{
    background-color: white;
    margin: 1rem 0rem 1rem 0rem;
    padding: 0.5rem;
    display: flex;
    /* flex-wrap: wrap; */
    border-radius: 2px;
    overflow-x: auto;
  -webkit-overflow-scrolling: touch;
     /* justify-content: center;  */
}

.event-tab{
    background-color: #bebebe;
    /* width: 100px; */
    border-radius: 2px;
    /* height: 100px; */
    display: block;
    margin: 0.5rem;
    cursor: pointer;
}
.para-two{
  margin: 8px 0 ;
}
.event-body{
    text-align: center;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.event-body p{
  text-transform: capitalize;
}
.active-event-tab{
    background-color: #018990;
    color: white;
}


.loader-wrapper{
  width: 80px;
  height: 80px;
  
  margin:10rem auto;
}
.loader {
  width: 100%;
  height: 100%;
  border: 5px solid #018990;
  border-top-color: #2cd9ff;
  border-bottom-color: #7effb2;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
  

}

@keyframes rotate {
  0% {
    transform: scale(1) rotate(360deg);
  }
  50% {
    transform:  scale(.8) rotate(-360deg);
  }
  100%{
    transform: scale(1) rotate(360deg);
  }
}