.active-result-tab{
    background-color: #018990;
    color: white;
    transition: 0.4s all;
}

.result-sport-container{
    background-color: white;
    margin: 0rem;
    padding: .7rem .5rem 1rem .5rem; 
    border-radius: 2px;
}
.result-sport-content{
    display: flex;
    justify-content: space-between;
}

.result-sport-content.justify-start {
    justify-content: flex-start !important;
}

.className-fix .result-event-select {
    max-width: 250px;
    flex: 1 1 auto;
    min-width: 180px;
    width: auto !important;
}

.className-fix .result-event-select.widthSmall {
    max-width: 180px;
    min-width: 180px;
}

.className-fix .result-event-select > [class$="container"] > [class$="control"] {
    width: 100% !important;
}

.result-event-select.hFix-100 [class$="control"] {
    height: auto;
}
.result-event-select.hFix-100 [class$="control"] > div:first-child {
    max-height: 75px;
    overflow: auto;
}
.result-select-body{
    width: auto !important;
    /* margin-right:20px; */
}
.result-event-select{
    width: 18% !important;
    /* margin-right:20px; */
}
.result-select-body.fancy-result-select-body {
    width: 25% !important;
}
#bookmaker-select{
    width: 24% !important;
}
.result-fancy-select{
    width: 15%;
}
.select-fields{
    width: 100%;
    border: 2px solid #cbcbcb;
    padding: .5rem;
    outline: none;
    height: 2.5rem;
    border-radius: .25rem;
    /* margin-top: .4rem; */
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
    display: inline-block
}
.result-input-body{
    width: 11%;
    
}
.match-result-table{
  overflow-x: auto;
}
.result-input{
    width: 100%;
    border: 2px solid #cbcbcb;
    padding: .5rem;
    outline: none;
    height: 2.5rem;
    /* background-color: #dbdbdb; */
    border-radius: .25rem;
    font-size: 1rem;
    /* margin-top: .4rem; */
}
.refresh-fancies{
    height: 2.4rem;
    border-radius: 4px;
    background-color: rgba(128, 128, 128, 0.934);
    color: white;
    cursor: pointer;
    text-align: center;
    border: none;
    padding: 0rem 2rem 0rem 2rem;
    display: flex;
    align-items: center;
}
.manager_downline{
    background-color: white;
   
}

.result-input-body{
    width: 200px;
}
.pending-result-btn{
    display: flex;
    align-items: center;
    width: 200px;
    /* justify-content: center  ; */
}
.suspend-result{
    display: flex;
    background-color: red;
    padding: 0.5rem;
    margin-left: 1rem;
    color: white;
    border-radius: .25rem;
}
.select-pending-container{
    width: 200px;
}
.event-name-container{
    width: 320px;
}
.total-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.result_status{
    display: flex;
    align-items: center;
}
.self-flexEnd {
    align-self: flex-end;
}
@media (max-width:768px) {
    .self-flexEnd {
        align-self: flex-start;
    }
    .result-sport-content{
   flex-direction: column;
    }
    
    .result-select-body{
        width: 100%;
    }
    .result-event-select{
        width: 100%;
    }
    .result-input-body{
        width: 100%;
    }
    .result-fancy-select{
        width: 100%;
    }
    .event-name-container{
        /* width: 200px; */
    }
}
@media   (min-width: 769px) and (max-width:867px) { 
    .result-sport-content{
        flex-wrap: wrap;
         }
         .result-select-body{
             width: 48%;
         }
         .result-event-select{
             width: 48%;
         }
         .result-input-body{
             width: 48%;
         }
         .result-fancy-select{
             width: 48%;
         }
         .select-pending-field{
            width: 100%;
         }
}

@media   (min-width: 868px)  and (max-width:1100px) { 
    .result-sport-content{
        flex-wrap: wrap;
         }
         .select-pending-field{
            width: 100%;
         }
         .result-select-body{
            width: 48%;
        }
        .result-event-select{
            width: 48%;
        }
        .result-input-body{
            width: 48%;
        }
        .result-fancy-select{
            width: 48%;
        }
}
 


.result-submit > button:disabled,
button[disabled]{
  /* border: 1px solid #999999; */
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
} 
 input:disabled, input[disabled]{
 background-color:#dbdbdb;
 cursor: not-allowed;
}


.container {
    display: flex;
    justify-content: flex-start;
    padding: 8px;
    margin: 0 auto;
    background: #dbdada;
    gap: 15px;
}

.btn-tab {
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 0;
    background-color: white;
    color: black;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-tab.selected-tab {
    background-color: #018990;
    color: white;
    transition: 0.4s all;
}

.tableResponsive-wrap {
    overflow-x: auto;
}

.table-scrollable-containers-new {
    width: 100%;
    overflow-x: auto;
    padding: none;
}

.operator-dropdown-container-new [class$="control"] > div:first-child {
    max-height: 88px;
    overflow: auto !important;
}

table.table-center tr th, table.table-center tr td {
    text-align: center;
}