.login-container{
    background-image: url('../../assets/login_banner.png');
    width: 100%;
    height: 100vh;
    background-size: cover;
    object-fit: cover;
    object-position: center;
    background-position: center;
}
.login-content{
    display: flex;
    justify-content: center;
}
.error{
    color: red;
}
.user-link{
  margin-top: 1rem;
  text-align: center;
}
.link{
  color: white;
  text-decoration: underline;
}
  .login-body{
      background-color: #054146;
      border-radius: .25rem;
      box-sizing: border-box;
     padding: 1rem 2rem 1rem 2rem;
      width: 23.6%;
      max-height: 380px;
      /* overflow-y: scroll; */
      overflow-x: hidden;
      overflow-y: hidden;
      margin: 10rem auto;
  }
  .logo-img{
    display: flex;
    justify-content: center;
  }
 .logo-img img{
    width: 50%;
    margin-top: 1rem;
    height: 50px;
 }


 .form-body input{
    height: 100%;
    width: 100%;
    outline: none;
    padding: .9rem;
    margin-top: 1.2rem;
    border-radius: 0.313rem;
    border: 1px solid lightgrey;
    border-bottom-width: 2px;
    letter-spacing: 1px;
    transition: all 0.3s ease;
 }
.form-body input::placeholder{
    font-size:1rem;
    color: #989898;
    font-weight: normal;
 }
 input:focus {
    border-color:  rgba(255, 192, 203, 0.799);
    /* color: white; */
  }
 .login-btn{
    margin-top: 1.2rem;
 }
 .login-btn button{
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    background-color: #018990;
    border: none;
    color: #fff;
    padding: .6rem;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
 }
 @media  (max-width: 768px) {
 .login-body{
   width: 80%;
 }}
 @media  (min-width: 1920px) {
  .login-body{
    width: 40%;
  }
 }
 @media (min-width:768px) and (max-width:1024px) {
   .login-container{
       background-size: cover;
       object-fit: cover;
       object-position: center;
       background-position: center;   
   }
   .login-body{
       width: 40%;
   }
}

 