.active-result-tab{
    background-color: #018990;
    color: white;
    transition: 0.4s all;
}

.result-sport-container{
    background-color: white;
    margin: 0rem;
    padding: .7rem .5rem 1rem .5rem; 
    border-radius: 2px;
}
.result-sport-content{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.result-sport-content-racing{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.result-select-body{
    width: 15%;
    /* margin-right:20px; */
}
.result-event-select{
    width:  fit-content !important;
    margin: 0 5px;
}
#bookmaker-select{
    width: 24%;
}
.result-fancy-select{
    width: 15%;
}
.select-field{
    width: 100%;
    border: 2px solid #cbcbcb;
    padding: .5rem;
    outline: none;
    height: 2.5rem;
    border-radius: .25rem;
    margin-top: .4rem;
}
.result-input-body{
    width: 11%;
    
}
.match-result-table{
  overflow-x: auto;
}
.result-input{
    width: 100%;
    border: 2px solid #cbcbcb;
    padding: .5rem;
    outline: none;
    height: 2.5rem;
    /* background-color: #dbdbdb; */
    border-radius: .25rem;
    font-size: 1rem;
    /* margin-top: .4rem; */
}



.manager_downline{
    background-color: white;
   
}

.result-input-body{
    width: 200px;
}
.pending-result-btn{
    display: flex;
    align-items: center;
    width: 200px;
    /* justify-content: center  ; */
}
.suspend-result{
    display: flex;
    background-color: red;
    padding: 0.5rem;
    margin-left: 1rem;
    color: white;
    border-radius: .25rem;
}
.select-pending-container{
    width: 200px;
}
.event-name-container{
    width: 320px;
}
.total-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.result_status {
    display: flex;
    align-items: center;
}
@media (max-width:768px) {
    .result-sport-content{
   flex-direction: column;
    }
    
    .result-select-body{
        width: 100%;
    }
    .result-event-select{
        width: 100%;
    }
    .result-input-body{
        width: 100%;
    }
    .result-fancy-select{
        width: 100%;
    }
    .event-name-container{
        /* width: 200px; */
    }
}
@media   (min-width: 769px) and (max-width:867px) { 
    .result-sport-content{
        flex-wrap: wrap;
         }
         .result-select-body{
             width: 48%;
         }
         .result-event-select{
             width: 48%;
         }
         .result-input-body{
             width: 48%;
         }
         .result-fancy-select{
             width: 48%;
         }
         .select-pending-field{
            width: 100%;
         }
}

@media   (min-width: 868px)  and (max-width:1100px) { 
    .result-sport-content{
        flex-wrap: wrap;
         }
         .select-pending-field{
            width: 100%;
         }
         .result-select-body{
            width: 48%;
        }
        .result-event-select{
            width: 48%;
        }
        .result-input-body{
            width: 48%;
        }
        .result-fancy-select{
            width: 48%;
        }
}
 


.result-submit > button:disabled,
button[disabled]{
  /* border: 1px solid #999999; */
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
} 
 input:disabled, input[disabled]{
 background-color:#dbdbdb;
 cursor: not-allowed;
}

