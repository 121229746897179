.bookmaker-container{
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    margin: 1rem;
}
.all-market-head{
    background-color: #e5e5e5;
    padding: .2rem 1rem .2rem 1rem;
    display: flex;
    border-radius: 4px;

    justify-content: space-between;
}
.all-back-lay{
    display: flex;
    align-items: center;
    width: 130px;
}
.all-back-lay span{
    width: 60px;
    display: flex;
    justify-content: center;
}
.all-back-lay-1{
    display: flex;
}
.suspend-bookmaker{
    position: absolute;
    font-size: 17px;
    color: #df1717;
    right: 1rem;
    text-transform: uppercase;
    font-weight: 800;
  }
.lay{
    margin-left: 2rem;
}
.all-market-body-container{
    border: 1px solid #dbdbdb;
    border-radius: 4px;
}
.all-market-container{
    height: 300px;
}
.all-market-body{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    z-index: 0;
    height: auto;
    align-items: center;
    padding: 0.5rem 0rem 0.5rem 0rem;
    /* height: 200px; */
    margin: 0.5rem 1rem 0.5rem 1rem;
}
.all-box,.all-box-bookmaker,.all-box-lay{
    background-color: #8dd2f0;
    margin: 0rem .1rem 0rem .1rem;
    padding: .5rem;
    width: 70px;
    height: 50px;
    display: flex;
    /* flex-wrap: wrap; */
    cursor: pointer;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    border-radius: 4px;
    text-align: center;
  }
  .all-box:nth-child(1),.all-box:nth-child(2),.all-box-lay:nth-child(2),.all-box-lay:nth-child(3){
    opacity: .7 !important;
}
  .min-max-bookmaker{
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
    /* margin-right: 1rem; */
    /* border-bottom: 1px solid #dbdbdb; */
}
@media (max-width:768px) {
    .all-box:nth-child(1),.all-box:nth-child(2),.all-box-lay:nth-child(2),.all-box-lay:nth-child(3){
        display: none;
    }
}