.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 20px;
  border-radius: 5px;
}

.left {
  display: flex;
  align-items: center;
}
  
.match-odds {
  font-weight: bold;
  color: #d9534f;
  margin-left: 5px;
}
  
.right {
  display: flex;
  gap: 10px;
}
  
.btn {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
}
  
.btn.unlock {
  background-color: #d9534f;
  color: white;
  border-color: #d9534f;
}

.btn.unlock:hover {
  background-color: #c9302c;
}

.btn.view-user {
  background-color: transparent;
  color: #0275d8;
  border-color: #0275d8;
  min-height: 37px;
  max-height: 57px;
  margin-left: -5px;
}
  
.btn.close-user {
  background-color: transparent;
  color: #d80202;
  border-color: #d80202;
  min-height: 37px;
  max-height: 57px;
  margin-left: -5px;
}
  
.btn.view-user:hover {
  background-color: #e7f1fb;
}
  
.table-container {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}
  
.custom-table th,
.custom-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.custom-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}
  
.custom-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}

.unlock-button {
  background-color: red;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}
  
.unlock-button:hover {
  background-color: darkred;
}

.view-bet-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.view-bet-button:hover {
  background-color: #0056b3;
}

.hidden {
  display: none;
}

.bets-table-header {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}
.close-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.close-button {
  background: red;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.bet-details-table {
  width: 100%;
  border-collapse: collapse;
}

.bet-details-table th,
.bet-details-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.surveillance-label {
  display: inline-block;
  padding: 8px 16px;
  background: #d9534f;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 10px;
  font-weight: normal;
  border-radius: 4px;
  text-align: center;
}

.table-cell {
  white-space: nowrap;
  text-align: center;
}

.surveil_wrap  .selectWrap-main [class$="control"] > div:first-child {
  max-height: 66px;
  overflow: auto !important;
}

.surveil_wrap {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 60%;
  flex: 1 1 auto;
  margin-left: auto;
}

.surveil_wrap .selectWrap-main {
  margin-left: auto;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.surveil_wrap .selectWrap-main > div {
  width: 300px;
}

.surveil_wrap .result-input {
  width: 200px;
  height: 9.5vh;
  min-height: 23px;
  max-height: 38px;
}